import { Divider, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { alpha } from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import SocialMedia from '@/components/socialMedia'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import useStore from '@/hooks/useStore'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'

const Footer = () => {
  const company = useStore((state) => state.company) as Company
  const menu = useStore((state) => state.menu) as Menu
  return (
    <Box
      sx={{
        bgcolor: '#000',
        py: 6,
        position: 'relative',
        '&::after': {
          display: 'block',
          width: 1,
          height: 1,
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          zIndex: 0,
        },
      }}
    >
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} lg={4}>
            <Stack spacing={2}>
              <Stack sx={{ width: 240, height: 100, position: 'relative' }}>
                {company?.logo && (
                  <Image
                    src={getStrapiImage(company?.logo)}
                    blurDataURL={getStrapiImagePlaceholder(company?.logo)}
                    placeholder="blur"
                    alt="Logo"
                    layout="fill"
                    objectFit="contain"
                    objectPosition={0}
                  ></Image>
                )}
              </Stack>
              <Typography color="#d8d8d8">{company?.description}</Typography>
              <Stack direction="row" spacing={1} sx={{ py: 2 }}>
                {company?.RS &&
                  Object.entries(company.RS)
                    ?.slice(1, 5)
                    .map((el) => (
                      <a
                        href={el[1]}
                        target="_blank"
                        title={el[0]}
                        rel="noopener noreferrer"
                        key={el[1]}
                      >
                        <IconButton sx={{ color: 'primary.main' }}>
                          <SocialMedia label={el[0]} />
                        </IconButton>
                      </a>
                    ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} lg={2.5}>
            <Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  sx={{
                    width: 15,
                    height: 15,
                    bgcolor: '#fff',
                    transform: 'rotate(45deg)',
                    boxShadow: '4px -4px 0 rgba(255,255,255,0.4)',
                  }}
                ></Stack>
                <Typography
                  variant="h6"
                  color="#fff"
                  fontSize={18}
                  component={Stack}
                  sx={{ py: 2 }}
                >
                  NAVIGATION
                </Typography>
              </Stack>

              {menu?.items?.map(
                (el) =>
                  !el?.subItems?.length && (
                    <Stack
                      direction="row"
                      key={el?.path}
                      alignItems="center"
                      spacing={1}
                      sx={{
                        color: '#d8d8d8',
                        '&:hover': { color: 'primary.main' },
                        py: 0.5,
                      }}
                    >
                      <DoubleArrowIcon fontSize="small" />
                      <Link href={el?.path} passHref>
                        <Typography component="a" gutterBottom>
                          {el?.label}
                        </Typography>
                      </Link>
                    </Stack>
                  )
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} lg={2.5}>
            <Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  sx={{
                    width: 15,
                    height: 15,
                    bgcolor: '#fff',
                    transform: 'rotate(45deg)',
                    boxShadow: '4px -4px 0 rgba(255,255,255,0.4)',
                  }}
                ></Stack>
                <Typography
                  variant="h6"
                  color="#fff"
                  fontSize={18}
                  component="span"
                  sx={{ py: 2 }}
                >
                  RESSOURCES
                </Typography>
              </Stack>
              {menu?.items?.map((el) =>
                el?.subItems?.map((subEl) => (
                  <Stack
                    direction="row"
                    key={subEl?.path}
                    alignItems="center"
                    spacing={1}
                    sx={{
                      color: '#d8d8d8',
                      '&:hover': { color: 'primary.main' },
                      py: 0.5,
                    }}
                  >
                    <DoubleArrowIcon fontSize="small" />
                    <Link href={subEl?.path} passHref>
                      <Typography component="a" gutterBottom>
                        {subEl?.label}
                      </Typography>
                    </Link>
                  </Stack>
                ))
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack
                sx={{
                  width: 15,
                  height: 15,
                  bgcolor: '#fff',
                  transform: 'rotate(45deg)',
                  boxShadow: '4px -4px 0 rgba(255,255,255,0.4)',
                }}
              ></Stack>
              <Typography
                variant="h6"
                color="#fff"
                fontSize={18}
                component="span"
                sx={{ py: 2 }}
              >
                CONTACT
              </Typography>
            </Stack>

            <List aria-label="address" dense>
              <ListItem disableGutters>
                <ListItemIcon>
                  <LocationOnIcon sx={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText
                  primary={company?.address}
                  sx={{ color: '#fff' }}
                />
              </ListItem>

              <Link
                href={`mailto:${company?.email}?subject=From Website - Your subject`}
                passHref
              >
                <ListItemButton disableGutters>
                  <ListItemIcon>
                    <AlternateEmailIcon sx={{ color: '#fff' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={company?.email}
                    sx={{ color: '#fff', wordBreak: 'break-all' }}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Grid>
        </Grid>
        <Divider color="#d8d8d8" sx={{ my: 4 }} />
        <Typography color="#fff" component="p" textAlign="center">
          &copy; ISDR/GL {new Date().getFullYear()}. All rights reserved.
          Designed by{' '}
          <Typography
            color="primary.main"
            component="a"
            href="https://uptodatedevelopers.com"
            target="_blank"
          >
            Uptodate Developers.{' '}
          </Typography>
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer
