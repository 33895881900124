import * as React from 'react'
import MenuContainer from './MenuContainer'
import SubMenu from './SubMenu'
import MenuItem from './MenuItem'

export interface IItems {
  id: number
  label: string
  path: string
  target: string
  subItems?: IItems[]
}

interface IMenu {
  items?: IItems[]
}

const Menu: React.FC<IMenu> = ({ items }) => {
  return (
    <MenuContainer>
      {items?.map((item) =>
        item?.subItems?.length ? (
          <SubMenu item={item} key={item?.label} />
        ) : (
          <MenuItem key={item?.label} item={item} />
        )
      )}
    </MenuContainer>
  )
}

export default Menu
