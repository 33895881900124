import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Stack } from '@mui/material'
import Fade from '@mui/material/Fade'
import MuiMenu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useRouter } from 'next/router'
import MenuItem from './MenuItem'
import type { IItems } from './Menu'

interface ISubMenu {
  open?: boolean
  anchorEl?: null | HTMLElement
  toggleMenu?: () => void
  mt?: number
  item: IItems
  width?: number
}

const SubMenu: React.FC<ISubMenu> = ({
  open = true,
  anchorEl,
  toggleMenu,
  mt = 2,
  item,
  width = 240,
}) => {
  const { route } = useRouter()
  const isActive = item.subItems?.find((el) => el.path === route)

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          cursor: 'pointer',
          '&:hover': { color: 'primary.main' },
          color: isActive ? 'primary.main' : 'inherit',
        }}
        onClick={toggleMenu}
      >
        <Typography>{item?.label}</Typography>
        <ArrowDownIcon />
      </Stack>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={toggleMenu}
        PaperProps={{
          sx: { mt, minWidth: width, boxShadow: '0 0 15px 0 rgba(0,0,0,0.2)' },
        }}
        TransitionComponent={Fade}
      >
        <Stack spacing={2} sx={{ px: 2, py: 1 }}>
          {item?.subItems?.map((item) => (
            <MenuItem key={item?.label} item={item} />
          ))}
        </Stack>
      </MuiMenu>
    </>
  )
}

export default SubMenu
