import { Stack } from '@mui/material'
import React from 'react'

interface IMenuContainer {
  children?: React.ReactElement | React.ReactElement[]
  spacing?: number
}

const MenuContainer: React.FC<IMenuContainer> = ({ children, spacing = 3 }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null)
  }

  const allProps = React.Children.map(children, (child) => {
    return child
      ? React.cloneElement(child, { open, anchorEl, toggleMenu })
      : null
  })

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={spacing}>
      {allProps}
    </Stack>
  )
}

export default MenuContainer
