import { createTheme } from '@mui/material/styles'

export const theme = (mode: 'light' | 'dark') =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: '#12A3B1',
      },
      secondary: {
        main: '#E43F46',
      },
      accent: {
        main: '#5B759F',
      },
      white: {
        main: '#fff',
      },
    },
    typography: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      h3: {
        fontWeight: 700,
      },
    },
  })

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary']
    white: Palette['primary']
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true
    white: true
  }
}
