import { theme } from '@/utils/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import type { NextPage } from 'next'
import { useDarkMode } from 'next-dark-mode'
import * as React from 'react'
import NavBar from '@/components/navBar'
import Footer from '@/components/footer'
import { useTheme } from '@mui/material/styles'
import NextNprogress from 'nextjs-progressbar'

const Wrapper: NextPage<{
  children: React.ReactElement[]
}> = ({ children }) => {
  const { darkModeActive } = useDarkMode()
  const mode = darkModeActive ? 'dark' : 'light'
  const { palette } = useTheme()

  return (
    <ThemeProvider theme={theme(mode)}>
      <NextNprogress
        color={palette.primary.main}
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={false}
        options={{ easing: 'ease', speed: 500 }}
      />
      <CssBaseline />
      <NavBar />
      {children}
      <Footer />
    </ThemeProvider>
  )
}

export default Wrapper
