import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import type { IItems } from './Menu'

interface IMenuItem {
  item: IItems
}

const MenuItem: React.FC<IMenuItem> = ({ item }) => {
  const { path, target, label } = item
  const { route } = useRouter()
  const isActive = `/${route.split('/')[1]}` === path

  if (target === '_blank') {
    return (
      <Typography
        component="a"
        color={isActive ? 'primary' : 'inherit'}
        sx={{ '&:hover': { color: 'primary.main' } }}
        href={path}
        target={target}
        rel="noopener noreferrer"
      >
        {label}
      </Typography>
    )
  }
  return (
    <Link href={path} passHref>
      <Typography
        component="a"
        color={isActive ? 'primary' : 'inherit'}
        sx={{ '&:hover': { color: 'primary.main' } }}
      >
        {label}
      </Typography>
    </Link>
  )
}

export default MenuItem
