import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { SvgIconComponent } from '@mui/icons-material'

const ComponentIcon = (Icon: SvgIconComponent) => {
  return <Icon fontSize="small" />
}

const getIconSrc = (label: string) => {
  switch (label) {
    case 'facebook':
      return ComponentIcon(FacebookIcon)
    case 'twitter':
      return ComponentIcon(TwitterIcon)
    case 'instagram':
      return ComponentIcon(InstagramIcon)
    case 'linkedin':
      return ComponentIcon(LinkedInIcon)
    default:
      break
  }
}

interface IProps {
  label: string
}

const SocialMedia: React.FC<IProps> = ({ label }) => {
  return <>{getIconSrc(label)}</>
}

export default SocialMedia
