import { useLockedBody } from '@/hooks/useLockedBody'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Button } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Menu } from './components'
import { useDarkMode } from 'next-dark-mode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import useStore from '@/hooks/useStore'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'

const NavBar = () => {
  const data = useStore((state) => state.menu)
  const logo = useStore((state) => state.company?.logo)
  const edusys = useStore((state) => state.company?.edusys)
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 750 })
  const { darkModeActive, switchToDarkMode, switchToLightMode } = useDarkMode()
  const { push } = useRouter()
  const [locked, setLocked] = useLockedBody()

  const toggleMode = () => {
    darkModeActive ? switchToLightMode() : switchToDarkMode()
  }

  const onShowMenu = () => {
    setLocked(!locked)
  }

  return (
    <AppBar
      position={trigger ? 'fixed' : 'absolute'}
      sx={{ bgcolor: 'background.paper' }}
      elevation={0}
      variant="outlined"
    >
      <Toolbar>
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row">
              <IconButton sx={{ display: { md: 'none' } }} onClick={onShowMenu}>
                <MenuIcon />
              </IconButton>
              <Stack
                justifyContent="flex-start"
                alignItems="flex-start"
                onClick={() => push('/')}
                sx={{
                  height: 40,
                  width: 120,
                  position: 'relative',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {logo && (
                  <Image
                    src={getStrapiImage(logo)}
                    blurDataURL={getStrapiImagePlaceholder(logo)}
                    placeholder="blur"
                    alt="Logo"
                    layout="fill"
                    objectFit="contain"
                    objectPosition={0}
                  ></Image>
                )}
              </Stack>
            </Stack>

            <Stack
              sx={{
                display: { xs: 'none', md: 'flex' },
                color: 'text.primary',
                fontWeight: 700,
                ...(locked && {
                  position: 'absolute',
                  display: 'flex',
                  top: 0,
                  bgcolor: 'background.default',
                  width: 1,
                  margin: 0,
                  left: 0,
                  height: '100vh',
                  bottom: 0,
                  right: 0,
                  overflow: 'hidden',
                  pl: 5,
                  pt: 10,
                }),
              }}
            >
              <IconButton
                sx={{
                  display: { md: 'none' },
                  position: 'absolute',
                  left: 30,
                  top: 10,
                }}
                onClick={onShowMenu}
              >
                <CloseIcon />
              </IconButton>

              <Menu items={data?.items} />
            </Stack>

            <IconButton onClick={toggleMode}>
              {darkModeActive ? (
                <DarkModeIcon />
              ) : (
                <LightModeIcon color="warning" />
              )}
            </IconButton>
            {edusys && (
              <a href={edusys} target="_blank" rel="noreferrer noopener">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  sx={{
                    display: locked ? 'none' : { xs: 'none', sm: 'block' },
                    px: 4,
                    borderRadius: 50,
                  }}
                >
                  Connexion
                </Button>
              </a>
            )}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
